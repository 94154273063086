<template>  
  <div class="transit" v-if="permission">
    <div class="lindao">
      <div class="Role">
        <img src="../assets/Aftercode/icon.png" alt="">
        当前角色：{{getone}}
      </div>
      <div class="Refresh" @click="Refresh">
        <img src="../assets/Aftercode/F5.png" alt="">
        刷新
      </div>
    </div>
    <div class="luc">
      <div class="lutitle1">鹿城区综合行政执法局</div>
      <div class="lutitle1">{{num}}年述职评议擂台赛评分表</div>
    </div>
    <div class="ons">
      <div @click="changeindex(index,item.F_Id)" :class="changindex==index?'onml onkl':'onml'" v-for="(item,index) in chang" :key="index">
        {{item.F_Name}}
      </div>
    </div>
    <div class="panda">
      <div @click="gosubmit(item.F_Name,item.F_Id,item.F_EnabledMark)" class="padnsain" v-for="(item,index) in departmentList" :key="index" >
        <div class="kukjl">
          <div>{{item.F_Name}}</div>
        </div>
        <div class="pingden nohappen" v-if="item.F_EnabledMark==1">
          未开始
        </div>
        <div class="pingden pinfen" v-else-if="item.F_EnabledMark==2">
          评分中
        </div>
        <div class="pingden jies" v-else-if="item.F_EnabledMark>=3">
          已结束
        </div>
      </div>
    </div>
    <div class="tijiao " @click="gotransfer()" v-if="pupwindow">
      <div class="onkl ssss">
        <div class="success">{{fname}}正在打分</div>
        <div class="queren" @click="gosubmit(fname,fid,fmark)">确认</div>
        <div class="queren guanbi" @click="pupwindow=false">关闭</div>
      </div>
    </div>
  </div>
  <div class="transit" v-else></div>
</template>

<script>
import {reactive,onMounted,onUnmounted} from 'vue'
import { toRefs } from '@vue/reactivity'
import {useRoute, useRouter} from "vue-router";
import {getTodaySummary} from '../api/index.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  raninfo,
  prolist,
  grouplist,
  check
} from '../api/index.js'
export default {
  name: 'App',
  components: {
  },
  setup(){
    const obj = reactive(
      {
        num:'',
        chang:[
        ],
        changindex:0,
        departmentList:[
        ],
        getone:'',
        randomNumber:0,
        whatNumber:0,
        changcid:'',
        permission:true,
        fname:'',
        fid:'',
        pupwindow:false,
        fmark:''
      }
    )
    const route = useRoute();
    function getzhiwei(){
      var what = route.query.WhatPosition?route.query.WhatPosition:''
      var random = route.query.random?route.query.random:''
      var canEnters = route.query.canEnter?route.query.canEnter:''
      // alert(what)
      check({
        canEnter:canEnters,
        random:random
      }).then(res=>{
        obj.whatNumber = what
        obj.randomNumber = random
        if(what==1){
          obj.getone = '局领导'
        }else if(what==2){
          obj.getone = '局中层'
        }
        if(res.data==1){
          getprolist()
          obj.permission = true
        }else{
          // if(canEnters===''){
          //   obj.permission = true
          //   getprolist()
          // }else{
            obj.permission = false
            
          // }
          
        }
      })
      
    }
    function changeindex(i,id){
      obj.changcid = id
      obj.changindex = i
      localStorage.setItem('changindex',i)
      if(i==0){
        obj.num ='一'
      }else if(i==1){
        obj.num ='二'
      }
      getgrouplistss(id)
    }
    const router = useRouter();
    //跳转登录页面
    function gosubmit(name,ids,type){
      // alert(name)
      var canEnters = route.query.canEnter?route.query.canEnter:''
      if(type==2){
        router.push({path:'/logins',query:{
          F_id:ids,
          F_name:name,
          F_Number:obj.randomNumber,
          WhatPosition:obj.whatNumber,
          canEnters:canEnters
        }});
      }else{
        ElMessage.error('当前不在打分时间')
      }
      
    }
    //获取随机数
    // function getnum(){
    //   raninfo({}).then(res=>{
    //     obj.randomNumber = res.data
    //   })
    // }
    //获取场次场次
    function getprolist(){
      prolist({}).then(res=>{
        obj.chang = res.data
        var lll = localStorage.getItem('changindex')
        if(lll){
          obj.changindex = lll
          obj.changcid = res.data[lll].F_Id
        }else{
          obj.changcid = res.data[0].F_Id
        }
        obj.num = res.data[0].F_Year
        localStorage.setItem('F_Year',res.data[0].F_Year)
        
        getgrouplistss(obj.changcid)
      })
    }
    //获取场次列表
    function getgrouplistss(id){
      grouplist({
        projectId:id
      }).then(res=>{
        obj.departmentList = res.data
        var nocex = 0
        var F_name = route.query.F_name?route.query.F_name:''//随机数
        
        // if(obj.isrefresh){
          for(var i =0;i<obj.departmentList.length;i++){
            if(obj.departmentList[i].F_EnabledMark==2){
              if(F_name===obj.departmentList[i].F_Name){

              }else{
                obj.fname= obj.departmentList[i].F_Name
                obj.fid = obj.departmentList[i].F_Id
                obj.fmark = obj.departmentList[i].F_EnabledMark
                console.log(obj.fid)
                ElMessageBox.alert(obj.departmentList[i].F_Name+ '正在打分', obj.departmentList[i].F_Name, {
                  confirmButtonText: '确认',
                  
                  callback:()=>{
                    gosubmit(obj.fname,obj.fid,obj.fmark)
                  }
                })
                break
              }
              
            }else if(obj.departmentList[i].F_EnabledMark>=3){
              nocex+=1
              if(nocex == obj.departmentList.length){
                ElMessage({
                  message:'当前场次打分完毕,请前往下一场',
                  type: 'warning',
                })
              }
            }
          }
        //   obj.isrefresh = false
        // }
        
      })
    }
    function Refresh(){
      obj.chang = []
      obj.departmentList = []
      obj.isrefresh = true
      getzhiwei()
      
    }
    onMounted(()=>{
      // getnum()
      getzhiwei()
      
      
    })
    onUnmounted(() => {
    })
    return{
      ...toRefs(obj),
      gosubmit,
      changeindex,
      getgrouplistss,
      Refresh
    }
  },
  methods:{
  }
}
</script>

<style lang="less">
  @import '../css/phys.less';
</style>
